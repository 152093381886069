// Lib imports
import React, { useEffect } from 'react';
import { Box, Carousel, Image } from 'grommet';

// Core imports
import { useToast } from 'granite-admin/core/components/Toast';
import useQuery from 'granite-admin/utils/useQuery';
// import Dashboard from 'assets/dashboard.png';
import AdminPortal1 from 'assets/adminportal1.svg';
import AdminPortal2 from 'assets/adminportal2.svg';
import ParentPortal1 from 'assets/parentportal1.svg';
import ParentPortal2 from 'assets/parentportal2.svg';

const SideContentForm = () => {
  const { errorToast } = useToast();
  const { query } = useQuery();

  useEffect(() => {
    if (query?.error) errorToast(query?.error.split('/?')[0]);
  }, [query, errorToast]);

  return (
    <Box height="100%" justify='center'>
      <Carousel Carousel controls={false} play={4000} >
        <Box alignSelf="center" flex={{ shrink: 0 }}>
          <Image src={window?.location?.pathname?.includes("/parent") ? ParentPortal1 : AdminPortal1} fit="contain" />
          {/* <Box align='center' margin='20px'>
            <Paragraph size="medium" color="white">
              Rollcall school bus management system works with schools and bus operators to design new routes and understand how children could spend less time on buses.
            </Paragraph>
          </Box> */}
        </Box>
        <Box alignSelf="center" flex={{ shrink: 0 }}>
          <Image src={window?.location?.pathname?.includes("/parent") ? ParentPortal2 : AdminPortal2} fit="contain" />
          {/* <Box align='center' margin='20px'>
            <Paragraph size="medium" color="white">
              Rollcall school bus management system works with schools and bus operators to design new routes and understand how children could spend less time on buses.
            </Paragraph>
          </Box> */}
        </Box>
        {/* <Box alignSelf="center" flex={{ shrink: 0 }}>
          <Image src={Dashboard} fit="contain" />
          <Box align='center' margin='20px'>
            <Paragraph size="medium" color="white">
              Rollcall school bus management system works with schools and bus operators to design new routes and understand how children could spend less time on buses.
            </Paragraph>
          </Box>
        </Box> */}
      </Carousel >
    </Box>
  );
};

export default SideContentForm;