// Lib imports
// import _ from 'lodash/fp';

// Core imports
import GraniteError from 'granite-admin/utils/granite-error';

// Application imports
import OrganisationApi from 'companySettings/gateways/organisation-api';
import SettingsEntity from 'companySettings/entities/settings';

import { ORGANISATION_EVENTS, ORGANISATION_CONSTANTS } from 'companySettings/controllers/events';
import { handleApiCall } from 'utils/handleApiCallController';

async function getMasterPreferences(eventEmitter, params) {
  return await handleApiCall(
    async () => {
      const payload = {
        params: { ...params, config_type: ORGANISATION_CONSTANTS.ORGANISATION_SETTING, page_size: 100 },
      };

      const data = await OrganisationApi.getMasterConfigs(payload);
      const list = data.results.map(e => new SettingsEntity(e));
      if (eventEmitter?.emit) eventEmitter.emit(ORGANISATION_EVENTS.MASTER_PREFERENCES_FETCH_SUCCESS, { list });
      return list;
    },
    eventEmitter,
    ORGANISATION_EVENTS.MASTER_PREFERENCES_FETCH_FAILURE,
  );
}

async function getSettings(eventEmitter, params) {
  return await handleApiCall(
    async () => {
      const payload = { params: { ...params, page_size: 100 } };
      const data = await OrganisationApi.getSettings(payload);
      const list = data?.results?.map(({ pk, value, setting_key }) => ({ pk, value, name: setting_key }));
      if (eventEmitter?.emit) eventEmitter.emit(ORGANISATION_EVENTS.SETTINGS_FETCH_SUCCESS, { list });
      return list;
    },
    eventEmitter,
    ORGANISATION_EVENTS.SETTINGS_FETCH_FAILURE,
  );
}

export { getMasterPreferences, getSettings };
