import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Box, Button, TextInput, Anchor, Heading, Spinner } from 'grommet';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import FormField from 'granite-admin/core/components/FormField';
import PasswordInput from 'granite-admin/core/components/PasswordInput';
import { useToast } from 'granite-admin/core/components/Toast';
import useQuery from 'granite-admin/utils/useQuery';

import { isSSOBtnVisible } from 'accounts/controllers/user';
import { LOGIN_EVENTS } from 'accounts/controllers/events';
import { getEmailQuery } from 'pages/parentLogin/helpers/parentLoginHelpers';
import { initialValues, konectSsoUrl, parentMainUrl, validationSchema } from 'pages/parentLogin/helpers/constants';
import LOGIN_MESSAGES from 'granite-admin/messages/login';
import { borderStyles, ssoLabelColor } from 'pages/parentLogin/styledComponents/styles';
import { STRINGS, STRING_URLS } from 'pages/parentLogin/strings';

const currentDomain = window.location;
const subdomain = currentDomain.host.split('.')[0] ? currentDomain.host.split('.')[0] : '';
let ssoRedirectUrl = `${konectSsoUrl}?current_url=${currentDomain.origin}/parent/login/&school=${subdomain}`;

const Loginform = ({ eventEmitter, loginClicked }) => {
  const { errorToast } = useToast();
  const { query } = useQuery();
  const [ssoVisible, setSsoVisible] = useState('');
  const [ssoLabel, setSsoLabel] = useState('SSO Login');
  const [loading, setLoading] = useState(true);

  const inputRef = useRef();
  const navigate = useNavigate();
  const handleForgotPassword = useCallback(async () => {
    const query = await getEmailQuery(inputRef);
    navigate(`${STRING_URLS.PARENT_FORGOT_PASSWORD}?${query}`);
  }, [inputRef, navigate]);

  useEffect(() => {
    const subscription = eventEmitter.getObservable().subscribe(event => {
      switch (event.type) {
        case LOGIN_EVENTS.SSO_SUCCESS:
          if (event.data?.results?.length) {
            event.data.results.forEach(i => {
              if (i['is_sso_enabled']) setSsoVisible(i['is_sso_enabled']?.value);
              if (i['forgerock_login_text']) setSsoLabel(i['forgerock_login_text']?.value || 'SSO Login');
            });
          }
          setLoading(false);
          break;
        case LOGIN_EVENTS.SSO_FAILURE:
          setSsoVisible('');
          setLoading(false);
          errorToast(event?.data?.title || STRINGS.ERROR_FETCH_SSO_DETAILS);
          break;
        case LOGIN_EVENTS.SSO_FAILURE:
          setSsoVisible('');
          setLoading(false);
          errorToast(event?.data || 'Unable to fetch SSO details');
          break;
        default:
      }
    });
    return () => subscription.unsubscribe();
  }, [eventEmitter, errorToast]);

  useEffect(() => {
    if (subdomain && currentDomain.href !== parentMainUrl) {
      const fetch = async () => {
        await isSSOBtnVisible(eventEmitter, subdomain);
      };
      fetch();
    }
    if (query?.error) errorToast(query?.error.split('/?')[0]);
  }, [eventEmitter, errorToast, query]);

  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    setErrors({});
    setSubmitting(true);
    await loginClicked(eventEmitter, values);
    setSubmitting(false);
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
        <>
          <Heading level={3}>{STRINGS.PARENT_PORTAL_LOGIN}</Heading>
          <Heading level={5} size="large" color="dark-2">
            {STRINGS.SIGN_IN_TO_ACCOUNT}
          </Heading>
          <form onSubmit={handleSubmit}>
            {/** FIXME: Move strings to messages */}
            <FormField
              name="email"
              label={LOGIN_MESSAGES.EMAIL_LABEL}
              error={touched.email && errors.email}
              normal={true}
            >
              <TextInput
                ref={inputRef}
                type="email"
                name="email"
                onChange={e => {
                  handleChange(e);
                }}
                onBlur={handleBlur}
                value={values.email}
                style={{ marginTop: '0px' }}
              />
            </FormField>
            <FormField
              name="password"
              label={LOGIN_MESSAGES.PASS_LABEL}
              required
              error={touched.password && errors.password}
              normal={true}
            >
              <PasswordInput
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                type="password"
                style={{ marginTop: '0px' }}
              />
            </FormField>
            <Box direction="row" gap="small" margin={{ top: 'medium' }}>
              <Button
                type="submit"
                alignSelf="start"
                label={
                  isSubmitting ? LOGIN_MESSAGES.SUBMIT_BUTTON_LABEL_SUBMITTING : LOGIN_MESSAGES.SUBMIT_BUTTON_LABEL
                }
                disabled={isSubmitting}
                primary
                color="secondary"
                style={{ borderRadius: '4px' }}
              />
              {loading && (
                <Box
                  align="center"
                  style={{ display: ssoVisible ? 'none' : '' }}
                  direction="row"
                  margin={{ left: 'medium' }}
                >
                  <Spinner border={borderStyles} />
                </Box>
              )}
              <Button
                alignSelf="end"
                label={ssoLabel}
                disabled={isSubmitting}
                primary
                style={{ display: !ssoVisible ? 'none' : '', borderRadius: '4px' }}
                color={ssoLabelColor}
                onClick={() => window.location.replace(ssoRedirectUrl)}
              />
            </Box>

            <Box align="start" margin={{ vertical: 'small' }}>
              <Anchor
                onClick={handleForgotPassword}
                as="span"
                label={LOGIN_MESSAGES.FORGOT_PASS_LINK_LABEL}
                style={{ fontSize: '12px' }}
              />
            </Box>
          </form>
        </>
      )}
    </Formik>
  );
};

Loginform.propTypes = {
  eventEmitter: PropTypes.object,
  loginClicked: PropTypes.func,
};

export default Loginform;
