import _ from 'lodash/fp';

import GraniteError from 'granite-admin/utils/granite-error';
import useTimeZone from 'granite-admin/utils/useTimeZone';

import { safeJsonParse } from 'utils/commonUtils';

const { country } = useTimeZone();
class User {
  constructor({ username, first_name, last_name, email, create_date, profile_image, phone, country_code, ...data }) {
    if (_.isNil(username) || !_.isString(username)) {
      throw new GraniteError('Userrname must be valid string');
    }
    this.pk = data.pk;
    this.username = username;
    this.firstName = first_name || '';
    this.lastName = last_name || '';
    this.email = email || '';
    this.isActive = data.is_active;
    this.isEmailVerified = data.is_email_verified;
    this.isPhoneVerified = data.is_phone_verified;
    this.isSystemAdmin = data.is_business_admin;
    this.permissions = [];
    this.permissionsName = [];
    this.phone =
      phone.length > 0
        ? {
            dialCode: phone?.split(' ')[0].replace('+', ''),
            number: phone.replace(/\s/g, '').replace(`+`, '').replace('-', ''),
            countryCode: country_code?.toLowerCase(),
            phone: phone,
          }
        : { number: '', dialCode: '1', countryCode: country?.toLowerCase() };
    this.createDate = create_date;
    this.profileImage = profile_image;
    this.organisations = data.organisations?.map(o => ({
      name: o.organisation__name,
      pk: o.organisation__pk,
      domain: o.organisation__domain,
      logo: o.organisation__logo__document,
    }));
    this.currentOrganisation = null;
    this.navigationLayout = 'top_menu_bar';
    this.twoFA = 'false';
    this.dateTimeFormat = '';
    this.dateFormat = '';
    this.timeFormat = '';
    this.timezone = '';
    this.userDateTimeFormat = '';
    this.userDateFormat = '';
    this.usertimeFormat = '';
    this.userTimezone = '';
    this.restPreferences = {};
    this.isLogoEnabled = false;
    this.isSmartRouteEnabled = 'false';
    this.migratedDate = '';
    this.paymentModuleActive = false;
    this.parentAppTabs = '';
    this.userTwoFA = 'false';
    this.ppAbsenceOn = false;
    this.stopGeofenceSize = '';
    this.userDataSourceWarning = '';
    this.redirectionAlertsEmails = '';
    this.portalEmailOn = false;
    this.portalSmsOn = false;
    this.triggerEmails = false;
    this.country = '';
    this.stopDurationDefault = '01:00';
    this.portalFutureVisibilityDays = 0;
    this.portalBusChangeEnable = false;
    this.portalFutureEditableDays = 0;
    this.busChangesHideDeleteButton = false;
    this.isEnableGlobalDashboard = false;
    this.restSettings = {};
    this.displayStopTimes = false;
    this.transportScreenAPI = false;
    this.isDashletsEnabled = false;
    this.routeMessageOption = '';
    this.twoFAId = '';
    this.twoFAOption = 'both';
    this.rolloverTabVisibility = false;
  }

  setAsAdmin() {
    this.isSystemAdmin = true;
  }

  removeAsAdmin() {
    this.isSystemAdmin = false;
  }

  setPermissions(data) {
    this.permissions = [...data];
  }

  setPermissionsName(data) {
    this.permissionsName = [...data];
  }

  setCurrentOrganisation(data) {
    this.currentOrganisation = { ...data };
  }

  setNavigationLayout(data) {
    this.navigationLayout = data;
  }

  setTimezone(tz) {
    this.timezone = tz;
  }

  set2FA(fa) {
    this.twoFA = fa;
  }

  setDateTimeFormat(dt) {
    this.dateTimeFormat = dt;
  }

  setDateFormat(df) {
    this.dateFormat = df;
  }

  setTimeFormat(tf) {
    this.timeFormat = tf;
  }

  setRestPreferences(pref) {
    this.restPreferences = { ...this.restPreferences, [pref.preferenceKey]: pref.value };
  }

  setRestSettings(setting) {
    this.restSettings = { ...this.restSettings, [setting.name]: setting.value };
  }

  setUserTimezone(tz) {
    this.userTimezone = tz;
  }
  setUserDateTimeFormat(dt) {
    this.userDateTimeFormat = dt;
  }

  setUserDateFormat(df) {
    this.userDateFormat = df;
  }

  setUserTimeFormat(tf) {
    this.usertimeFormat = tf;
  }

  setIsLogoEnabled(tf) {
    this.isLogoEnabled = tf;
  }
  setIsSmartRouteEnable(tf) {
    this.isSmartRouteEnabled = tf;
  }
  setMigratedDate(tf) {
    this.migratedDate = tf;
  }
  setPaymentModuleActive(tf) {
    this.paymentModuleActive = JSON.parse(tf);
  }
  setParentAppTabs(tf) {
    this.parentAppTabs = tf;
  }
  setUserTwoFA(tf) {
    this.userTwoFA = tf;
  }
  setPpAbsenceOn(tf) {
    this.ppAbsenceOn = JSON.parse(tf);
  }
  setStopGeofenceSize(tf) {
    this.stopGeofenceSize = tf;
  }
  setUserDataSourceWarning(tf) {
    this.userDataSourceWarning = tf;
  }
  setRedirectionAlertsEmails(tf) {
    this.redirectionAlertsEmails = tf;
  }
  setPortalEmailOn(tf) {
    this.portalEmailOn = JSON.parse(tf);
  }
  setPortalSmsOn(tf) {
    this.portalSmsOn = JSON.parse(tf);
  }
  setTriggerEmails(tf) {
    this.triggerEmails = JSON.parse(tf);
  }
  setCountry(fa) {
    this.country = fa;
  }
  setPortalFutureVisibilityDays(tf) {
    this.portalFutureVisibilityDays = JSON.parse(tf);
  }
  setPortalBusChangeEnable(tf) {
    this.portalBusChangeEnable = +tf;
  }
  setPortalFutureEditableDays(tf) {
    this.portalFutureEditableDays = JSON.parse(tf);
  }
  setStopDurationDefault(tf) {
    this.stopDurationDefault = tf;
  }
  setbusChangesHideDeleteButton(tf) {
    this.busChangesHideDeleteButton = JSON.parse(tf);
  }
  setisEnableGlobalDashboard(tf) {
    this.isEnableGlobalDashboard = JSON.parse(tf);
  }
  setTransportManifestLambda(tf) {
    this.transportManifestLambda = JSON.parse(tf);
  }
  setDisplayStopTimes(tf) {
    this.displayStopTimes = JSON.parse(tf);
  }
  setTransportScreenAPI(tf) {
    this.transportScreenAPI = JSON.parse(tf);
  }
  setIsDashletsEnabled(tf) {
    this.isDashletsEnabled = JSON.parse(tf);
  }
  setRouteMessageOption(tf) {
    this.routeMessageOption = tf;
  }
  set2FAId(fa) {
    this.twoFAId = fa;
  }
  setTwoFAOption(fa) {
    this.twoFAOption = fa;
  }
  setRolloverTabVisibility(tf) {
    this.rolloverTabVisibility = safeJsonParse(tf);
  }
}

export default User;
