import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { getAuthToken } from 'granite-admin/utils/auth-singleton';

import LinearLayout from 'common/LinearLayout.js';

const PublicRoute = ({ component: Component, ...rest }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const authToken = getAuthToken();
  if (authToken) {
    // Call getProfile async, so that we can display profile in Header
  }
  return (
    <LinearLayout>
      <Component {...rest} navigate={navigate} location={location} />
    </LinearLayout>
  );
};

export default PublicRoute;
