import { connect, useSelector } from 'react-redux';
import React, { useState, useCallback, useEffect, useMemo, useContext } from 'react';
import { Box, Text, Image } from 'grommet';
import { FormDown } from 'grommet-icons';
import { withTheme } from 'styled-components';
import { PropTypes } from 'prop-types';

import EventEmitter from 'granite-admin/utils/event-emitter';
import * as OrganisationDucks from 'granite-admin/organisations/ducks/organisations';
import { getTenantAccess } from 'granite-admin/utils/auth-singleton';
import * as UserDucks from 'granite-admin/accounts/ducks/user';
import useThemeConfigs from 'granite-admin/utils/useThemeConfigs';
import { getInitialValues } from 'granite-admin/pages/themeConfigs/utils';
import { ConfigContext } from 'granite-admin/core/components/ConfigProvider';
// import { getAuthToken } from 'granite-admin/utils/auth-singleton';
import { getCookie, deleteCookie } from 'granite-admin/utils/storage';
import { useToast } from 'granite-admin/core/components/Toast';
import {
  StyledBoxSchool,
  StyledDropBoxSchool,
  StyledDropButtonSchool,
} from 'common/TwoFALayer/styledComponents/styles';
import { DASHLETS_EDIT_EVENTS } from 'dashlets/controller/constants';
import { getParticularOrganisations } from 'dashlets/controller/dashlets';
import { STRINGS } from 'common/TwoFALayer/strings';
import { setCookiesAndRedirect, cookieElseSetter } from './TwoFALayer/helpers/common';

const BAOptions = ({ bAOrganisation, selectedOrganisation, theme, onSelect }) => {
  return (
    <Box gap="small" height={{ max: 'large' }}>
      {bAOrganisation.length > 0 ? (
        bAOrganisation
          ?.sort((a, b) => a?.name?.localeCompare(b?.name))
          .filter(org => org.pk !== selectedOrganisation.pk)
          .map(i => (
            <StyledDropBoxSchool key={i.pk} className="LeftNavItem" onClick={() => onSelect(i)} focusIndicator={false}>
              <Text>{i.name}</Text>
            </StyledDropBoxSchool>
          ))
      ) : (
        <StyledDropBoxSchool align="center" hoverColor={theme.global.colors.dropButtonHover}>
          <Text size="medium">{STRINGS.NO_SCHOOLS_FOUND}</Text>
        </StyledDropBoxSchool>
      )}
    </Box>
  );
};

const Options = ({ organisations, onSelect, theme, selectedOrganisation }) => {
  return (
    <Box gap="small" height={{ max: 'large' }}>
      {organisations.length > 0 ? (
        organisations
          ?.sort((a, b) => a?.name?.localeCompare(b?.name))
          .filter(org => org.pk !== selectedOrganisation.pk)
          .map(i => (
            <StyledDropBoxSchool key={i.pk} className="LeftNavItem" onClick={() => onSelect(i)} focusIndicator={false}>
              <Text>{i.name}</Text>
            </StyledDropBoxSchool>
          ))
      ) : (
        <StyledDropBoxSchool align="center" hoverColor={theme.global.colors.dropButtonHover}>
          <Text size="medium">{STRINGS.NO_SCHOOLS_FOUND}</Text>
        </StyledDropBoxSchool>
      )}
    </Box>
  );
};

const SchoolLogoDropdown = ({ theme, dispatch, organisations, selectedOrganisation, userProfile }) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(selectedOrganisation?.name);
  const [bAOrganisation, setBAOrganisation] = useState([]);
  const [logoEnabled, setLogoEnabled] = useState('');
  const { getThemeConfigs } = useThemeConfigs();
  const themeConfigsValues = getInitialValues(getThemeConfigs);
  const hasTenantAccess = getTenantAccess();
  const config = useContext(ConfigContext);
  const { errorToast } = useToast();
  const userPreferenceData = useSelector(({ accounts }) => accounts.user.profile);
  const eventEmitter = useMemo(() => new EventEmitter(), []);

  const getOrganisations = useCallback(async () => {
    const params = {
      page: 1,
      page_size: 10000,
      is_active: true,
      exclude_industry: 'Konect',
      fetch_organisation_plan: true,
      ordering: 'name',
    };
    await getParticularOrganisations(eventEmitter, params);
  }, [errorToast]);

  useEffect(() => {
    const subscription = eventEmitter.getObservable().subscribe(event => {
      switch (event.type) {
        case DASHLETS_EDIT_EVENTS.GET_ORG_SUCCESS:
          setBAOrganisation(event.data);

          break;
        case DASHLETS_EDIT_EVENTS.GET_ORG_FAILURE:
          errorToast(event.data || STRINGS.FETCH_ORGANIZATIONS_FAILED);
          break;

        default:
      }
    });
    return () => subscription.unsubscribe();
  }, [eventEmitter, errorToast, setBAOrganisation]);

  useEffect(() => {
    if (userProfile.isSystemAdmin && selectedOrganisation.pk && !hasTenantAccess) {
      getOrganisations();
    }
  }, [getOrganisations, hasTenantAccess, selectedOrganisation.pk, userProfile.isSystemAdmin]);

  const switchOrganisation = useCallback(
    organisation => {
      const currentSubdomain = window.location?.host.split('.')[0];
      deleteCookie('organisationData');
      deleteCookie('BAorigin');
      deleteCookie('redirectDomain');
      if (config?.subdomainRedirectEnabled && organisation?.domain && organisation?.domain !== currentSubdomain) {
        setCookiesAndRedirect(organisation, bAOrganisation);
        // localStorage.clear();
      } else if (config.subdomainRedirectEnabled && getCookie('redirectDomain') === 'yes') {
        cookieElseSetter();
      } else {
        dispatch({
          type: 'organisations/UPDATE_SELECTED_ORGANISATION',
          data: organisation,
        });
        dispatch({
          type: 'accounts/sidebar/UPDATE_SIDEBAR',
          data: [],
        });
        window.location.href = '/adminLogin';
      }
    },
    [config.subdomainRedirectEnabled, bAOrganisation.length, dispatch],
  );

  const onSelect = useCallback(
    selectedItem => {
      setSelected(selectedItem.name);
      switchOrganisation(selectedItem);
      setOpen(false);
    },
    [switchOrganisation],
  );
  useEffect(() => {
    setLogoEnabled(userPreferenceData?.isLogoEnabled);
  }, [userPreferenceData]);

  return (
    <>
      <StyledDropButtonSchool
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        dropProps={{ align: { top: 'bottom' } }}
        dropContent={
          userProfile.isSystemAdmin && selectedOrganisation.pk && !hasTenantAccess ? (
            <BAOptions
              bAOrganisation={bAOrganisation}
              onSelect={onSelect}
              selectedOrganisation={selectedOrganisation}
              theme={theme}
            />
          ) : (
            <Options
              organisations={organisations}
              onSelect={onSelect}
              theme={theme}
              selectedOrganisation={selectedOrganisation}
            />
          )
        }
        focusIndicator={false}
      >
        <StyledBoxSchool direction="row" align="center" pad="0.5rem" gap="small" justify="between">
          {logoEnabled === 'true' ? (
            <Box direction="row" gap="small" height="40px" align="center" justify="center">
              <Box height="100%">
                <Image src={themeConfigsValues?.logo} alt="School Logo" height="100%" />
              </Box>
              <Text>{selected}</Text>
            </Box>
          ) : (
            <Text>{selected}</Text>
          )}
          <FormDown size="small" color={theme.global.colors['brand']} />
        </StyledBoxSchool>
      </StyledDropButtonSchool>
    </>
  );
};

SchoolLogoDropdown.propTypes = {
  theme: PropTypes.any,
  dispatch: PropTypes.func,
  organisations: PropTypes.array,
  selectedOrganisation: PropTypes.object,
  userProfile: PropTypes.object,
};

Options.propTypes = {
  organisations: PropTypes.array,
  onSelect: PropTypes.func,
  theme: PropTypes.any,
  selectedOrganisation: PropTypes.object,
};

BAOptions.propTypes = {
  onSelect: PropTypes.func,
  theme: PropTypes.any,
  selectedOrganisation: PropTypes.object,
  bAOrganisation: PropTypes.array,
};

const mapStateToProps = state => ({
  selectedOrganisation: OrganisationDucks.selectedOrganisation(state),
  organisations: OrganisationDucks.organisations(state),
  userProfile: UserDucks.profile(state),
});

const mapDispatchToProps = dispatch => ({
  dispatch: dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(SchoolLogoDropdown));
