import React, { lazy } from 'react';
import Home from 'pages/home';
import AdminLogin from 'pages/adminLogin';
import OtpSubmit from 'pages/otpSubmit';
import AdminForget from 'pages/adminForget';
import AdminReset from 'pages/adminReset';
import ParentLogin from 'pages/parentLogin';
import OnBoarding from 'pages/onBoarding';
import ParentReset from 'pages/parentReset';
import Logout from 'common/Logout';
import GoToRoute from 'pages/goToRoute';
import ForgetPassword from 'pages/forgotpassword';
import RedirectOperational from 'pages/redirectOperational';
import DriverDirections from 'pages/driverDirections';
import MyBoard from 'pages/dashboard/components/myBoard';
import RollOver from 'pages/rollOver';
// const EmployeeList = lazy(() => import('pages/employeeList'));
const Holidays = lazy(() => import('pages/holidays'));
// const EmployeeEdit = lazy(() => import('pages/employeeEdit'));
// const CustomerList = lazy(() => import('pages/customerList'));
// const CustomerEdit = lazy(() => import('pages/customerEdit'));
// const EmployeeImport = lazy(() => import('pages/employeeImport'));
// const Tracking = lazy(() => import('pages/tracking'));
// const GeoFence = lazy(() => import('pages/geoFences'));
// const ProductsList = lazy(() => import('pages/products/productList'));
// const ProductForm = lazy(() => import('pages/products/productEdit'));
// const VariantForm = lazy(() => import('pages/products/variantEdit'));
const PluginsList = lazy(() => import('pages/pluginList'));
// const PluginsDetails = lazy(() => import('pages/pluginDetail'));
// const CalendarDemo = lazy(() => import('pages/calendarDemo'));
const AdminList = lazy(() => import('pages/admin'));
const CampusList = lazy(() => import('pages/campus'));
const DashletsList = lazy(() => import('pages/dashlets'));
const DriversList = lazy(() => import('pages/drivers'));
// const Parameters = lazy(() => import('pages/parameters'));
const ParentList = lazy(() => import('pages/parents'));
const RoutePageList = lazy(() => import('pages/routes'));
const StopsList = lazy(() => import('pages/stops'));
const StudentList = lazy(() => import('pages/students'));
const NonStudentTravellerList = lazy(() => import('pages/nonStudentTraveller'));
const TransportList = lazy(() => import('pages/transport'));
const ZonesList = lazy(() => import('pages/zones'));
const Booking = lazy(() => import('pages/bookings'));
const PayPerPeriodList = lazy(() => import('pages/payPerPeriodPayment'));
const EventGroupList = lazy(() => import('pages/eventGroups'));
const ParentPortal = lazy(() => import('pages/parentsPortal'));
const SmartRoute = lazy(() => import('pages/smartRoute'));
const TransportScreen = lazy(() => import('pages/adminTransport'));
const Reports = lazy(() => import('pages/reports'));
const OperationalDashboard = lazy(() => import('pages/operationalBoard'));
const GlobalDashboard = lazy(() => import('pages/globalDashboard'));
const AMPMMapping = lazy(() => import('pages/ampmMapping'));
const BusinessBoard = lazy(() => import('pages/businessBoard'));
const Basic = lazy(() => import('pages/companySettings/components/Basic'));
const BusinessAdminList = lazy(() => import('pages/businessAdminList'));
const BusinessAdminEdit = lazy(() => import('pages/businessAdminEdit'));
const Settings = lazy(() => import('pages/companySettings'));
const ThemeConfigs = lazy(() => import('pages/themeConfigs'));
const NotificationTemplateList = lazy(() => import('pages/notificationTemplateList'));
const NotificationTemplateListEdit = lazy(() => import('pages/notificationTemplateEdit'));
const ProfilePage = lazy(() => import('pages/profile'));
const Dashboard = lazy(() => import('./pages/dashboard'));
const RollOverDashboard = lazy(() => import('pages/rollOverV2'));
const RollOverDevices = lazy(() => import('pages/rolloverDevices'));

/**********************************************/
/**********************************************/
// path: <URL on which the page will open up> //
// exact: <By default true. if your page contains Tabs, then false>
// component: <the React file which will be used to show UI>,
// layout: < Can be 'HeaderLayout' or 'DashboardLayout(if your page needs sidemenu, use this)'>
// publicOnly: to be used if can only be opened up without login true,
// private: to be used if can only be opened up with login true,
/**********************************************/
/**********************************************/
export default [
  {
    path: '/',
    component: Home,
    layout: 'HeaderLayout',
  },
  {
    path: '/logout',
    private: true,
    component: Logout,
    noSidebar: true,
    shouldFetchHelpTexts: false,
    shouldFetchPermissions: false,
  },
  {
    path: '/operational',
    exact: true,
    component: RedirectOperational,
    layout: 'HeaderLayout',
  },
  {
    path: '/company-settings/*',
    private: true,
    layout: 'DashboardLayout',
    tabs: [
      {
        content: <Basic />,
        title: 'Basic',
        path: 'basic',
        icon: <></>,
      },
      {
        content: <Settings />,
        title: 'Settings',
        path: 'settings',
        icon: <></>,
      },
      {
        content: <ThemeConfigs />,
        title: 'Theme & Configs',
        path: 'theme-configs',
        icon: <></>,
      },
    ],
    breadcrumbPath: [{ label: 'Settings' }, { label: 'School Settings' }],
    heading: {
      title: 'School Settings',
      subTitle: 'Edit & update your School Settings',
    },
  },
  {
    path: '/login',
    publicOnly: true,
    component: AdminLogin,
    layout: 'HeaderLayout',
  },
  {
    path: '/otp-submit',
    publicOnly: true,
    component: OtpSubmit,
    layout: 'HeaderLayout',
  },
  {
    path: '/get-started',
    private: true,
    component: OnBoarding,
    layout: 'LinearLayout',
  },
  {
    path: '/admin/forgot_password',
    publicOnly: true,
    component: AdminForget,
    layout: 'HeaderLayout',
  },
  {
    path: '/forgot-password',
    publicOnly: true,
    component: ForgetPassword,
    layout: 'HeaderLayout',
  },
  {
    path: '/admin/reset_password',
    publicOnly: true,
    component: AdminReset,
    layout: 'HeaderLayout',
  },
  {
    path: '/parent/login',
    publicOnly: true,
    component: ParentLogin,
    layout: 'ParentLayout',
  },
  {
    path: '/parent/otp-submit',
    publicOnly: true,
    component: OtpSubmit,
    layout: 'HeaderLayout',
  },
  {
    path: '/parent/forgot-password',
    publicOnly: false,
    component: ForgetPassword,
    layout: 'ParentLayout',
  },
  {
    path: '/parent/reset_password',
    publicOnly: true,
    component: ParentReset,
    layout: 'ParentLayout',
  },
  {
    path: '/api/get-route-stop-list',
    public: true,
    component: DriverDirections,
    layout: 'HeaderLayout',
  },
  {
    path: '/admin/rollover/*',
    private: true,
    component: RollOver,
    layout: 'HeaderLayout',
  },
  // {
  //   path: '/users',
  //   private: true,
  //   component: EmployeeList,
  //   layout: 'DashboardLayout',
  //   breadcrumbPath: [{ label: 'Users' }],
  //   heading: {
  //     title: 'Users',
  //     subTitle: 'View & manage all your Users profiles',
  //   },
  // },
  {
    path: '/parent/*',
    private: true,
    component: ParentPortal,
    layout: 'ParentLayout',
    heading: {
      title: 'Parent Portal',
      subTitle: 'View & manage all your Parent Portal Profiles',
    },
  },
  {
    path: '/setting-holiday',
    private: true,
    component: Holidays,
    layout: 'DashboardLayout',
    breadcrumbPath: [{ label: 'Holidays' }],
    heading: {
      title: 'Holidays',
      subTitle: 'View And Manage Holidays',
    },
  },
  {
    path: '/reports',
    private: true,
    component: Reports,
    layout: 'DashboardLayout',
    breadcrumbPath: [{ label: 'Reports' }],
    heading: {
      title: 'Reports',
      subTitle: 'View & manage all Reports',
    },
  },
  // {
  //   path: '/users-import',
  //   private: true,
  //   component: EmployeeImport,
  //   layout: 'DashboardLayout',
  //   breadcrumbPath: [{ label: 'Users', path: '/users/' }, { label: 'Bulk Import Users' }],
  //   heading: {
  //     title: 'Bulk Import Users',
  //   },
  // },
  // {
  //   path: '/users/:id',
  //   private: true,
  //   component: EmployeeEdit,
  //   layout: 'DashboardLayout',
  //   breadcrumbPath: [{ label: 'Users', path: '/users' }, { label: ':id' }],
  //   heading: {
  //     title: 'User',
  //     subTitle: 'Basic and Contact Information',
  //   },
  //   permissions: ['employee_update'],
  // },
  // {
  //   path: '/customers/list/:status',
  //   private: true,
  //   component: CustomerList,
  //   layout: 'DashboardLayout',
  //   breadcrumbPath: [{ label: 'Manage Customers' }, { label: ':status' }],
  //   heading: {
  //     title: 'Customers',
  //     subTitle: 'Create & Manage Customers',
  //   },
  // },
  // {
  //   path: '/customers/:id',
  //   private: true,
  //   component: CustomerEdit,
  //   layout: 'DashboardLayout',
  //   breadcrumbPath: [{ label: 'Manage Customers', path: '/customers/list/new' }, { label: ':id' }],
  //   heading: {
  //     title: 'Customer',
  //     subTitle: 'Basic Details, Notes, Documents & Audits',
  //   },
  //   actions: {
  //     objectId: ':id',
  //     contentTypeName: 'customers_customer',
  //     contentTypeId: 27,
  //     actionItems: ['notes', 'audits', 'docs'],
  //   },
  //   permissions: ['customer_update'],
  // },
  // {
  //   path: '/tracking',
  //   private: true,
  //   component: Tracking,
  //   layout: 'DashboardLayout',
  //   breadcrumbPath: [{ label: 'Customer' }, { label: 'Tracking' }],
  //   heading: {
  //     title: 'Customer Tracking',
  //     subTitle: 'View tracking details of Customers',
  //   },
  // },
  // {
  //   path: '/geo-fences',
  //   private: true,
  //   component: GeoFence,
  //   layout: 'DashboardLayout',
  //   breadcrumbPath: [{ label: 'Customer' }, { label: 'Geo Fence' }],
  //   heading: {
  //     title: 'Customer Geo Fences',
  //     subTitle: 'View tracking details of Customers',
  //   },
  // },
  // {
  //   path: '/products',
  //   private: true,
  //   component: ProductsList,
  //   layout: 'DashboardLayout',
  //   breadcrumbPath: [{ label: 'Products' }],
  //   heading: {
  //     title: 'Products',
  //     subTitle: 'View And Manage Products',
  //   },
  // },
  {
    path: '/route_creation',
    private: true,
    component: SmartRoute,
    fields: ['Event Type', 'GPS Scanning Mode', 'In Smart Route', 'USER TIMEZONE'],
    noHeader: true,
    noAlert: true,
    layout: 'DashboardLayout',
  },
  {
    path: '/admin/transport',
    private: true,
    component: TransportScreen,
    layout: 'DashboardLayout',
    fields: ['Reason'],
    breadcrumbPath: [{ label: 'Transport Screen' }],
    heading: {
      title: 'Transport Screen',
      subTitle: 'View & manage your Transport Screen',
    },
  },
  // {
  //   path: '/product/:id',
  //   private: true,
  //   component: ProductForm,
  //   layout: 'DashboardLayout',
  //   breadcrumbPath: [{ label: 'Products', path: '/products' }, { label: ':id' }],
  //   heading: {
  //     title: 'Product',
  //     subTitle: 'Product Information',
  //   },
  // },
  // {
  //   path: '/product-variant/:id',
  //   private: true,
  //   component: VariantForm,
  //   layout: 'DashboardLayout',
  //   breadcrumbPath: [{ label: 'Products', path: '/products' }, { label: ':id' }],
  //   heading: {
  //     title: 'Product Variant',
  //     subTitle: 'Product Variant Information',
  //   },
  // },
  {
    path: '/plugins',
    private: true,
    component: PluginsList,
    layout: 'DashboardLayout',
    breadcrumbPath: [{ label: 'Plugins' }],
    heading: {
      title: 'Plugins',
      subTitle: 'Manage plugins',
    },
  },
  // {
  //   path: '/plugins/:id',
  //   private: true,
  //   component: PluginsDetails,
  //   layout: 'DashboardLayout',
  //   breadcrumbPath: [{ label: 'Plugins', path: '/plugins' }, { label: ':id' }],
  //   heading: {
  //     title: 'Plugin Details',
  //     subTitle: 'View and manage plugin details',
  //   },
  //   noHeader: true,
  // },
  // {
  //   path: '/calendar-demo',
  //   private: true,
  //   component: CalendarDemo,
  //   layout: 'DashboardLayout',
  //   breadcrumbPath: [{ label: 'Calendar Demo', path: '/calendar-demo' }],
  //   heading: {
  //     title: 'Calendar Demo',
  //   },
  // },
  {
    path: '/admin',
    private: true,
    component: AdminList,
    layout: 'DashboardLayout',
    breadcrumbPath: [{ label: 'Admin' }],
    heading: {
      title: 'Parameters',
      subTitle: 'View And Manage Admin',
      button: { label: 'Add New' },
    },
  },
  {
    path: '/campus',
    private: true,
    component: CampusList,
    layout: 'DashboardLayout',
    breadcrumbPath: [{ label: 'Campus' }],
    heading: {
      title: 'Parameters',
      subTitle: 'View And Manage Campus',
      button: { label: 'Add New' },
    },
  },
  {
    path: '/dashlets',
    private: true,
    component: DashletsList,
    layout: 'DashboardLayout',
    fields: ['dashlet_settings'],
    breadcrumbPath: [{ label: 'Dashlets' }],
    heading: {
      title: 'Dashlets',
      subTitle: 'View And Manage Dashlets',
    },
  },
  {
    path: '/drivers',
    private: true,
    component: DriversList,
    layout: 'DashboardLayout',
    breadcrumbPath: [{ label: 'Drivers' }],
    heading: {
      title: 'Parameters',
      subTitle: 'View And Manage Drivers',
      button: { label: 'Add New' },
    },
  },
  // {
  //   path: '/parameters',
  //   private: true,
  //   component: Parameters,
  //   layout: 'DashboardLayout',
  //   breadcrumbPath: [{ label: 'Parameters' }],
  //   heading: {
  //     title: 'Parameters',
  //     subTitle: 'View And Manage Parameters',
  //   },
  // },
  {
    path: '/parents',
    private: true,
    component: ParentList,
    fields: ['ParentStatus'],
    layout: 'DashboardLayout',
    breadcrumbPath: [{ label: 'Parents' }],
    heading: {
      title: 'Parameters',
      subTitle: 'View And Manage Parents',
    },
  },
  {
    path: '/routes',
    private: true,
    fields: ['Event Type', 'GPS Scanning Mode', 'In Smart Route', 'USER TIMEZONE'],
    component: RoutePageList,
    layout: 'DashboardLayout',
    breadcrumbPath: [{ label: 'Routes' }],
    heading: {
      title: 'Routes',
      subTitle: 'View And Manage Routes',
    },
  },
  {
    path: '/stops',
    private: true,
    component: StopsList,
    layout: 'DashboardLayout',
    fields: ['In Smart Route'],
    breadcrumbPath: [{ label: 'Stops' }],
    heading: {
      title: 'Stops',
      subTitle: 'View And Manage stops',
    },
  },
  {
    path: '/student',
    private: true,
    fields: ['Student Type', 'Payment Frequency'],
    component: StudentList,
    layout: 'DashboardLayout',
    breadcrumbPath: [{ label: 'Students' }],
    heading: {
      title: 'Parameters',
      subTitle: 'View And Manage students',
    },
  },
  {
    path: '/nonStudentTraveller',
    private: true,
    component: NonStudentTravellerList,
    layout: 'DashboardLayout',
    breadcrumbPath: [{ label: 'Non Student Traveller' }],
    heading: {
      title: 'Non Student Traveller',
      subTitle: 'View And Manage Non Student Traveller',
    },
  },
  {
    path: '/transport',
    private: true,
    component: TransportList,
    layout: 'DashboardLayout',
    breadcrumbPath: [{ label: 'Transport' }],
    heading: {
      title: 'Transport',
      subTitle: 'View And Manage transport',
    },
  },
  {
    path: '/zones',
    private: true,
    component: ZonesList,
    layout: 'DashboardLayout',
    breadcrumbPath: [{ label: 'Zones' }],
    heading: {
      title: 'Zones',
      subTitle: 'View And Manage zones',
    },
  },
  {
    path: '/booking',
    private: true,
    component: Booking,
    layout: 'DashboardLayout',
    breadcrumbPath: [{ label: 'Booking' }],
    heading: {
      title: 'Booking',
      subTitle: 'View And Manage Booking',
    },
  },
  {
    path: '/pay-per-period',
    private: true,
    component: PayPerPeriodList,
    fields: ['Payment Frequency'],
    layout: 'DashboardLayout',
    breadcrumbPath: [{ label: 'Pay Per Period' }],
    heading: {
      title: 'Pay Per Period',
      subTitle: 'View And Manage Pay Per Period',
    },
  },
  {
    path: '/event-group',
    private: true,
    component: EventGroupList,
    layout: 'DashboardLayout',
    breadcrumbPath: [{ label: 'Event Groups' }],
    heading: {
      title: 'Parameters',
      subTitle: 'View And Manage Event Groups',
      button: { label: 'Add New' },
    },
  },
  {
    path: '/operational-dashboard',
    private: true,
    component: OperationalDashboard,
    fields: ['Event Type', 'GPS Scanning Mode', 'In Smart Route', 'USER TIMEZONE'],
    layout: 'DashboardLayout',
    breadcrumbPath: [{ label: 'Operational Dashboard' }],
    heading: {
      title: 'Operational Dashboard',
      subTitle: 'View And Manage dashboard',
    },
  },
  {
    path: '/global-dashboard',
    private: true,
    component: GlobalDashboard,
    layout: 'DashboardLayout',
    fields: ['dashlet_settings'],
    breadcrumbPath: [{ label: 'Global Dashboard' }],
    heading: {
      title: 'Global Dashboard',
      subTitle: 'View And Manage global dashboard',
    },
  },
  {
    path: '/route-mapping',
    private: true,
    component: AMPMMapping,
    layout: 'DashboardLayout',
    breadcrumbPath: [{ label: 'Route Mapping' }],
    heading: {
      title: 'Route Mapping',
      subTitle: 'View And Manage Route Mapping',
    },
  },
  {
    path: '/business-board/',
    private: true,
    component: BusinessBoard,
    layout: 'DashboardLayout',
    breadcrumbPath: [{ label: 'Organizations List' }],
    heading: {
      title: 'Organizations List',
      subTitle: 'My sub organizations',
    },
  },
  {
    path: '/go-to-route',
    component: GoToRoute,
  },
  {
    path: '/notification-templates',
    private: true,
    component: NotificationTemplateList,
    layout: 'DashboardLayout',
    breadcrumbPath: [{ label: 'Notification Templates' }],
    heading: {
      title: 'Notifications Templates',
      subTitle: 'View & manage all your Notifications Templates',
    },
  },
  {
    path: '/notification-templates/:id',
    private: true,
    component: NotificationTemplateListEdit,
    layout: 'DashboardLayout',
    breadcrumbPath: [{ label: 'Notifications Templates', path: '/notification-templates' }, { label: ':id' }],
    heading: {
      title: 'Notifications Template',
      subTitle: 'Create/Update Notifications Template',
    },
  },
  {
    path: '/profile/*',
    private: true,
    component: ProfilePage,
    layout: 'DashboardLayout',
    breadcrumbPath: [{ label: 'Main' }, { label: 'Profile', path: '/profile' }],
    heading: {
      title: 'Edit User Profile',
      subTitle: 'Edit & update your Profile details',
    },
  },
  {
    path: '/dashboard',
    private: true,
    component: Dashboard,
    layout: 'DashboardLayout',
  },
  {
    path: '/my-boards',
    private: true,
    component: MyBoard,
    layout: 'DashboardLayout',
  },
  {
    path: '/my-boards',
    private: true,
    component: MyBoard,
    layout: 'DashboardLayout',
  },
  {
    path: '/business-admins',
    private: true,
    component: BusinessAdminList,
    layout: 'DashboardLayout',
    breadcrumbPath: [{ label: 'Users' }],
    heading: {
      title: 'Users',
      subTitle: 'View & manage all your users',
    },
  },
  {
    path: '/business-admins/:id',
    private: true,
    component: BusinessAdminEdit,
    layout: 'DashboardLayout',
    breadcrumbPath: [{ label: 'Users', path: '/business-admins' }, { label: ':id' }],
    heading: {
      title: 'Users',
      subTitle: 'Add/Edit user',
    },
  },
  {
    path: '/rollover-dashboard',
    private: true,
    component: RollOverDashboard,
    layout: 'DashboardLayout',
    breadcrumbPath: [{ label: 'RollOver' }],
    heading: {
      title: 'RollOver',
      subTitle: 'View & manage rollovers',
    },
  },
  {
    path: '/rollover-devices',
    private: true,
    component: RollOverDevices,
    layout: 'DashboardLayout',
    breadcrumbPath: [{ label: 'Devices' }],
    heading: {
      title: 'Devices',
      subTitle: 'View & manage Devices',
    },
  },
];
