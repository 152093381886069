// Lib Imports
import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Image, Heading, Paragraph, ResponsiveContext } from 'grommet';
import { Apple, Android } from 'grommet-icons';
import styled, { withTheme } from 'styled-components';

import logoImage from 'assets/RollCallVertLogoSmall.png';

const StyledButton = styled(Button)`
  cursor: pointer;
  font-family: 'Roboto', Sans-serif;
  color: white;
  // width: 15rem;
  // font-size: 1.2rem;
  font-weight: 400;
  border: none;
  background-color: transparent;
  border-radius: 40px 40px 40px 40px;
  // padding: 1rem 1.2rem;
  // margin-right: 10rem;
  text-decoration: none;
  font-size: ${props => (props.fontSize ? props.fontSize : '1.2rem')};
  width: ${props => (props.width ? props.width : '15rem')};
  padding: ${props => (props.padding ? props.padding : '1rem 1.2rem')};
`;

const StyledIosBtn = styled(StyledButton)`
  background-image: linear-gradient(90deg, #194592 44%, rgb(35, 180, 35) 100%);
  &: hover {
    background-color: transparent;
    background-image: linear-gradient(90deg, rgb(35, 180, 35) 44%, #194592 100%);
    border: none;
  }
`;

const StyledAndroidBtn = styled(StyledButton)`
  margin: auto;
  background-image: linear-gradient(90deg, rgb(218, 145, 9) 44%, rgb(7, 7, 68) 80%);
  &: hover {
    background-color: transparent;
    background-image: linear-gradient(90deg, rgb(7, 7, 68) 44%, rgb(218, 145, 9) 80%);
  }
`;

const Home = ({ theme }) => {
  const size = React.useContext(ResponsiveContext);
  return (
    <Box background="#ffffff" flex="grow">
      <Box
        direction="row"
        justify="center"
        background="#ffffff"
        height="100vh"
        width="100%"
      >
        <Box margin="auto" width="100%" background="#ffffff" align="center">
          <Link onClick={() => window.open('https://rollcall.com.au')} to="#">
            <Image alt="logo" width="200px" margin="0rem" src={logoImage} />
          </Link>
          <Heading textAlign="center" size="small" color="#2f4e84">
            Start Managing Your Childs Bus Journeys Today
          </Heading>
          <Paragraph textAlign="center" size={size === 'small' ? 'medium' : 'large'} color="#00000066">
            Download The Rollcall Parent App on ios or Android
          </Paragraph>
          <Box
            direction={size === 'small' ? 'column' : 'row'}
            gap={size === 'small' ? 'medium' : '100px'}
            justify="center"
            align="center"
          >
            <Link onClick={() => window.open('https://apps.apple.com/au/app/rollcall-parent/id1504206392')} to="#">
              <StyledIosBtn
                {...(size === 'small' && { padding: '5px 18.36px', width: 'auto', fontSize: '15px' })}
                label="iOS Application"
                icon={<Apple color="#ffffff" size="medium" />}
                reverse
              />
            </Link>
            <Link
              onClick={() =>
                window.open('https://play.google.com/store/apps/details?id=com.rollcallparentapp&hl=en_AU&gl=US')
              }
              to="#"
            >
              <StyledAndroidBtn
                {...(size === 'small' && { padding: '5px', width: 'auto', fontSize: '15px' })}
                label="Android Application"
                icon={<Android color="#fff" size="medium" />}
                reverse
              />
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default withTheme(Home);
