import React, { useState, useCallback } from 'react';
import { Box, Text, DropButton } from 'grommet';
import { CaretDownFill } from 'grommet-icons';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { withTheme } from 'styled-components';

const StyledBox = styled(Box)`
  min-width: 30px;
  text-align: center;
  max-width: 180px;
  & span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
const StyledDropBox = styled(Box)`
  padding : 5px 10px;
  flex-shrink: 0;
  &:hover{
    background: ${({ hoverColor }) => hoverColor};
`;

const Options = ({ items, onSelect, theme, optionMaxHeight, itemKey }) => {
  return (
    <Box
      style={{ maxHeight: optionMaxHeight }}
      overflow={{ vertical: 'auto' }}
      border={{ side: 'all', size: '1px', color: '#ddd' }}
    >
      {items.length > 0 ? (
        items.map(i => (
          <StyledDropBox
            key={typeof i === 'object' ? i.value : i}
            hoverColor={theme.global.colors.dropButtonHover}
            onClick={() => onSelect(i)}
            focusIndicator={false}
          >
            <Text>{typeof i === 'object' ? i[itemKey] : i}</Text>
          </StyledDropBox>
        ))
      ) : (
        <StyledDropBox align="center" hoverColor={theme.global.colors.dropButtonHover}>
          <Text size="medium"> No Options</Text>
        </StyledDropBox>
      )}
    </Box>
  );
};

const SelectButtonGroup = ({
  items,
  onChange,
  placeholder,
  theme,
  optionMaxHeight,
  value,
  bgColor,
  itemKey,
  disabled,
}) => {
  const [open, setOpen] = useState(false);
  const onSelect = useCallback(
    selectedItem => {
      onChange(selectedItem);
      setOpen(false);
    },
    [onChange],
  );

  return (
    <Box>
      <DropButton
        open={open}
        disabled={disabled}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        dropProps={{ align: { top: 'bottom', left: 'left' } }}
        dropContent={
          <Options
            items={items}
            onSelect={onSelect}
            theme={theme}
            optionMaxHeight={optionMaxHeight}
            itemKey={itemKey}
          />
        }
        focusIndicator={false}
        // style={{ background: bgColor }}
      >
        <Box
          direction="row"
          align="center"
          justify="between"
          border="all"
          round="4px"
          background="#fff"
          style={{ width: 'fit-content' }}
        >
          <StyledBox pad="small">
            {value ? <Text> {typeof value === 'object' ? value?.[itemKey] : value} </Text> : <Text>No Options</Text>}
          </StyledBox>
          <Box pad="small" border="left">
            <CaretDownFill size="small" color="brand" />
          </Box>
        </Box>
      </DropButton>
    </Box>
  );
};

SelectButtonGroup.defaultProps = {
  // placeholder: 'Select',
  optionMaxHeight: '190px',
  // bgColor: '#fff',
  itemKey: 'label',
  disabled: false,
};

SelectButtonGroup.propTypes = {
  items: PropTypes.array,
  onChange: PropTypes.func,
  // placeholder: PropTypes.string,
  theme: PropTypes.any,
  optionMaxHeight: PropTypes.any,
  value: PropTypes.any,
  // bgColor: PropTypes.any,
  itemKey: PropTypes.any,
  disabled: PropTypes.bool,
};

Options.propTypes = {
  items: PropTypes.array,
  onSelect: PropTypes.func,
  theme: PropTypes.any,
  optionMaxHeight: PropTypes.any,
  itemKey: PropTypes.any,
};

export default withTheme(SelectButtonGroup);
